import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <p>
          <a href="https://www.buymeacoffee.com/OctoComparison" target="blank">
            <img src="https://img.buymeacoffee.com/button-api/?text=Support&emoji=&slug=OctoComparison&button_colour=008ebd&font_colour=ffffff&font_family=Inter&outline_colour=ffffff&coffee_colour=FFDD00" />
          </a>
          <br />
          <br />
          Follow{" "}
          <a href="https://twitter.com/OctoComparison" target="blank">
            OctoComparison
          </a>{" "}
          on Twitter
          <br />
          Developed by & © 2023{" "}
          <a href="https://twitter.com/Jakosaur" target="blank">
            Jacob Tammadge
          </a>
          <br />
          Version 0.9.16
        </p>
      </div>
    );
  }
}
