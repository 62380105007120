import React from "react";
import logo from "./Header/logo.png";

export default class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <span id="advert"></span>
        <img
          className="logo"
          alt=""
          src={logo}
          width="64px"
          height="64px"
        ></img>
        <h1>OctoComparison</h1>
      </div>
    );
  }
}
