import React from "react";

export default class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <center>
          <p>
            <br />
            Unfortunately, the day has come to deprecate OctoComparison. I just
            can’t provide an experience that accurately shows pricing data for
            all users, especially those on the latest Octopus Tracker tariffs.
            <br /> <br />
            I don’t know what the future holds but if the Octopus Energy API
            starts returning the latest Octopus Tracker historical and latest
            pricing data, I can revisit development of the version I’ve had in
            the works.
            <br />
            <br />
            But for now, unfortunately this is goodbye. Thank you so much for
            the support! 👋🐙
            <br />
            <br />
            Haven't switched to Octopus Energy yet? Use{" "}
            <a href="https://share.octopus.energy/aqua-eagle-393">
              this link
            </a>{" "}
            for £50 account credit!
            <br />
            <br />
            Data provided by the Octopus Energy API
            <br />
            Pricing <b>isn't</b> calculated or verified by Octopus Energy
            <br />
            This website is in no way associated with Octopus Energy, but they
            sure do an awesome job over there!{" "}
            <span role="img" aria-label="octopus">
              🐙
            </span>
            <br />
            Icon made by{" "}
            <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
              Freepik
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
            <br /> <br />
          </p>
        </center>
      </div>
    );
  }
}
