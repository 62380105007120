import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";

import "./App.css";

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Router>
        <div className="app">
          {/* <ServiceWorkerWrapper /> */}
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            {/* <Route component={NotFound} /> */}
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}
