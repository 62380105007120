import { createStore } from "redux";

// import logger from "redux-logger";
// import thunk from "redux-thunk";
// import promise from "redux-promise-middleware";

import reducers from "./reducers";

// const middleware = applyMiddleware(promise(), thunk, logger());

export default createStore(
  reducers,
  // middleware,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
